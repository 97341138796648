<template>
  <div>
    <h1 v-if="!isLogin">{{ $t('quickUser.support.HelpCenterMessage') }}</h1>
    <h1 v-else>{{ $t('quickUser.support.HelpCenterSuccessMessage') }}</h1>
  </div>
</template>

<script>
import api from "@/plugins/api";
import store from '@/store'

export default {
  name: "JWTLogin",
  data() {
    return {
      isLogin: false
    }
  },
  created() {
    if (!store.getters['user/isAuthenticated']) {
      this.$router.push('/login?redirect=/jwt-login');
    }
  },
  mounted() {
    api.get('/v2/zendesk/jwt')
        .then(res => {
          this.isLogin = true;
          window.open(res.data.auth_url);
          setTimeout(() => {this.$router.go(-1)}, 1000)
        }).catch(reason => {
          console.error('reason', reason)
        })
  }
}
</script>

<style scoped>

</style>
